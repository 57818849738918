import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useScores from "../hooks/useScores";
import useHandicapData from "../hooks/useHandicapData";
import TrainingCalendar from "./TrainingCalendar";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
`;

const Section = styled.section`
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

const Input = styled.input`
  margin: 8px;
  padding: 8px 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  width: 100%;
  max-width: 60px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 768px) {
    max-width: 45px;
    padding: 6px;
    font-size: 12px;
    margin: 4px;
  }
`;

const TextInput = styled.input`
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin: 10px 0;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  background: linear-gradient(135deg, ${props => props.theme.colors.primary}, ${props => props.theme.colors.secondary});
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 12px;
  }
`;

const DeleteButton = styled(Button)`
  background: linear-gradient(135deg, #ff4d4d, #ff1a1a);
  padding: 8px 16px;
  font-size: 0.9em;
`;

const EditButton = styled(Button)`
  background: linear-gradient(135deg, #4CAF50, #45a049);
  padding: 8px 16px;
  font-size: 0.9em;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  
  th, td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }

  th {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  tr:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    th, td {
      padding: 8px;
      font-size: 12px;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 4px;
`;

const ScoreGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
`;

const NineHolesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 8px;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const HoleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
`;

const HoleNumber = styled.div`
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  margin-bottom: 4px;
  font-size: 0.9rem;
`;

const InputLabel = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 2px;
`;

const CheckboxLabel = styled.label`
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 4px;
`;

const LogoutButton = styled(Button)`
  background: linear-gradient(135deg, #ff9966, #ff5e62);
  margin-top: 1rem;
`;

const AdminHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const WelcomeText = styled.div`
  color: #ffffff;
  font-size: 1.1rem;
`;

const ConfirmDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 400px;
  width: 90%;
`;

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 999;
`;

const DialogTitle = styled.h3`
  color: #ffffff;
  margin: 0;
  text-align: center;
`;

const DialogButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

const CancelButton = styled(Button)`
  background: linear-gradient(135deg, #666666, #444444);
`;

const Subtitle = styled.div`
  color: #ffffff;
  text-align: center;
`;

const AdminDashboard = ({ setIsAuthenticated }) => {
  const { scores, addScore, deleteScore, updateScore } = useScores();
  const [editingId, setEditingId] = useState(null);
  const [holeScores, setHoleScores] = useState(Array(18).fill(""));
  const [holePars, setHolePars] = useState(Array(18).fill(""));
  const [gir, setGIR] = useState(Array(18).fill(false));
  const [fir, setFIR] = useState(Array(18).fill(false));
  const [driveDistance, setDriveDistance] = useState(Array(18).fill(""));
  const [putts, setPutts] = useState(Array(18).fill(""));
  const [proximityToHole, setProximityToHole] = useState(Array(18).fill(""));
  const [courseName, setCourseName] = useState("");
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const { addHandicapData } = useHandicapData();
  const [handicapIndex, setHandicapIndex] = useState("");
  const [scoreDifferential, setScoreDifferential] = useState("");
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();

  // Verify authentication on mount
  useEffect(() => {
    const checkAuth = () => {
      if (!auth.currentUser || auth.currentUser.email !== 'contact@cameronlowegolf.com') {
        setIsAuthenticated(false);
        navigate('/login');
      }
    };

    const unsubscribe = auth.onAuthStateChanged(checkAuth);
    return () => unsubscribe();
  }, [setIsAuthenticated, navigate]);

  const handleHoleScoreChange = (index, value) => {
    const updatedHoleScores = [...holeScores];
    updatedHoleScores[index] = value;
    setHoleScores(updatedHoleScores);
  };

  const handleHoleParChange = (index, value) => {
    const updatedHolePars = [...holePars];
    updatedHolePars[index] = value;
    setHolePars(updatedHolePars);
  };

  const handleGIRChange = (index) => {
    const updatedGIR = [...gir];
    updatedGIR[index] = !updatedGIR[index];
    setGIR(updatedGIR);
  };

  const handleFIRChange = (index) => {
    const updatedFIR = [...fir];
    updatedFIR[index] = !updatedFIR[index];
    setFIR(updatedFIR);
  };

  const handleDriveDistanceChange = (index, value) => {
    const updatedDriveDistance = [...driveDistance];
    updatedDriveDistance[index] = value;
    setDriveDistance(updatedDriveDistance);
  };

  const handlePuttsChange = (index, value) => {
    const updatedPutts = [...putts];
    updatedPutts[index] = value;
    setPutts(updatedPutts);
  };

  const handleProximityChange = (index, value) => {
    const updatedProximity = [...proximityToHole];
    updatedProximity[index] = value;
    setProximityToHole(updatedProximity);
  };

  const calculateTotalScore = () => {
    return holeScores.reduce(
      (total, score) => total + (parseInt(score) || 0),
      0
    );
  };

  const handleHandicapSubmit = async (e) => {
    e.preventDefault();
    try {
      const newHandicapData = {
        handicapIndex: parseFloat(handicapIndex),
        scoreDifferential: parseFloat(scoreDifferential),
        date: new Date().toISOString(),
      };
      await addHandicapData(newHandicapData);
      setHandicapIndex("");
      setScoreDifferential("");
    } catch (error) {
      console.error("Error adding handicap data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newScore = {
        totalScore: calculateTotalScore(),
        holeScores: holeScores.map(Number),
        holePars: holePars.map(Number),
        gir,
        fir,
        driveDistance: driveDistance.map(Number),
        putts: putts.map(Number),
        proximityToHole: proximityToHole.map(Number),
        courseName,
        eventName,
        date,
      };

      if (editingId) {
        await updateScore(editingId, newScore);
        setEditingId(null);
      } else {
        await addScore(newScore);
      }

      // Reset form
      setHoleScores(Array(18).fill(""));
      setHolePars(Array(18).fill(""));
      setGIR(Array(18).fill(false));
      setFIR(Array(18).fill(false));
      setDriveDistance(Array(18).fill(""));
      setPutts(Array(18).fill(""));
      setProximityToHole(Array(18).fill(""));
      setCourseName("");
      setEventName("");
      setDate("");
    } catch (error) {
      console.error("Error saving score:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteScore(id);
    } catch (error) {
      console.error("Error deleting score:", error);
    }
  };

  const handleEdit = (score) => {
    setEditingId(score.id);
    setHoleScores(score.holeScores);
    setHolePars(score.holePars);
    setGIR(score.gir);
    setFIR(score.fir);
    setDriveDistance(score.driveDistance);
    setPutts(score.putts);
    setProximityToHole(score.proximityToHole);
    setCourseName(score.courseName);
    setEventName(score.eventName);
    setDate(score.date);
  };

  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await signOut(auth);
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleLogoutCancel = () => {
    setShowLogoutConfirm(false);
  };

  if (!auth.currentUser) {
    return null; // or a loading spinner
  }

  return (
    <DashboardContainer>
      <AdminHeader>
        <WelcomeText>
          Welcome, {auth.currentUser.email}
        </WelcomeText>
        <LogoutButton onClick={handleLogoutClick}>
          Logout
        </LogoutButton>
      </AdminHeader>

      {showLogoutConfirm && (
        <>
          <DialogOverlay />
          <ConfirmDialog>
            <DialogTitle>Confirm Logout</DialogTitle>
            <Subtitle>Are you sure you want to log out?</Subtitle>
            <DialogButtons>
              <CancelButton onClick={handleLogoutCancel}>
                Cancel
              </CancelButton>
              <LogoutButton onClick={handleLogoutConfirm}>
                Logout
              </LogoutButton>
            </DialogButtons>
          </ConfirmDialog>
        </>
      )}

      <Section>
        <SectionTitle>Add New Score</SectionTitle>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Course Details</Label>
            <TextInput
              type="text"
              placeholder="Course Name"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
            />
            <TextInput
              type="text"
              placeholder="Event Name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
            <TextInput
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>Hole Scores</Label>
            <ScoreGrid>
              <NineHolesSection>
                {Array(9).fill().map((_, index) => (
                  <HoleSection key={`hole-front-${index + 1}`}>
                    <HoleNumber>Hole {index + 1}</HoleNumber>
                    <InputLabel>Score</InputLabel>
                    <Input
                      type="number"
                      value={holeScores[index]}
                      onChange={(e) => handleHoleScoreChange(index, e.target.value)}
                      placeholder="Score"
                    />
                    <InputLabel>Par</InputLabel>
                    <Input
                      type="number"
                      value={holePars[index]}
                      onChange={(e) => handleHoleParChange(index, e.target.value)}
                      placeholder="Par"
                    />
                    <CheckboxLabel>
                      <Checkbox
                        checked={gir[index]}
                        onChange={() => handleGIRChange(index)}
                      />
                      GIR
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <Checkbox
                        checked={fir[index]}
                        onChange={() => handleFIRChange(index)}
                      />
                      FIR
                    </CheckboxLabel>
                    <InputLabel>Drive</InputLabel>
                    <Input
                      type="number"
                      value={driveDistance[index]}
                      onChange={(e) => handleDriveDistanceChange(index, e.target.value)}
                      placeholder="Yards"
                    />
                    <InputLabel>Putts</InputLabel>
                    <Input
                      type="number"
                      value={putts[index]}
                      onChange={(e) => handlePuttsChange(index, e.target.value)}
                      placeholder="#"
                    />
                    <InputLabel>Proximity</InputLabel>
                    <Input
                      type="number"
                      value={proximityToHole[index]}
                      onChange={(e) => handleProximityChange(index, e.target.value)}
                      placeholder="Feet"
                    />
                  </HoleSection>
                ))}
              </NineHolesSection>

              <NineHolesSection>
                {Array(9).fill().map((_, index) => (
                  <HoleSection key={`hole-back-${index + 10}`}>
                    <HoleNumber>Hole {index + 10}</HoleNumber>
                    <InputLabel>Score</InputLabel>
                    <Input
                      type="number"
                      value={holeScores[index + 9]}
                      onChange={(e) => handleHoleScoreChange(index + 9, e.target.value)}
                      placeholder="Score"
                    />
                    <InputLabel>Par</InputLabel>
                    <Input
                      type="number"
                      value={holePars[index + 9]}
                      onChange={(e) => handleHoleParChange(index + 9, e.target.value)}
                      placeholder="Par"
                    />
                    <CheckboxLabel>
                      <Checkbox
                        checked={gir[index + 9]}
                        onChange={() => handleGIRChange(index + 9)}
                      />
                      GIR
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <Checkbox
                        checked={fir[index + 9]}
                        onChange={() => handleFIRChange(index + 9)}
                      />
                      FIR
                    </CheckboxLabel>
                    <InputLabel>Drive</InputLabel>
                    <Input
                      type="number"
                      value={driveDistance[index + 9]}
                      onChange={(e) => handleDriveDistanceChange(index + 9, e.target.value)}
                      placeholder="Yards"
                    />
                    <InputLabel>Putts</InputLabel>
                    <Input
                      type="number"
                      value={putts[index + 9]}
                      onChange={(e) => handlePuttsChange(index + 9, e.target.value)}
                      placeholder="#"
                    />
                    <InputLabel>Proximity</InputLabel>
                    <Input
                      type="number"
                      value={proximityToHole[index + 9]}
                      onChange={(e) => handleProximityChange(index + 9, e.target.value)}
                      placeholder="Feet"
                    />
                  </HoleSection>
                ))}
              </NineHolesSection>
            </ScoreGrid>
          </FormGroup>

          <Button type="submit">
            {editingId ? 'Update Score' : 'Add Score'}
          </Button>
        </Form>
      </Section>

      <Section>
        <SectionTitle>Add Handicap Data</SectionTitle>
        <Form onSubmit={handleHandicapSubmit}>
          <FormGroup>
            <Label>Handicap Index</Label>
            <TextInput
              type="number"
              step="0.1"
              value={handicapIndex}
              onChange={(e) => setHandicapIndex(e.target.value)}
              placeholder="Enter Handicap Index"
            />
          </FormGroup>
          <FormGroup>
            <Label>Score Differential</Label>
            <TextInput
              type="number"
              step="0.1"
              value={scoreDifferential}
              onChange={(e) => setScoreDifferential(e.target.value)}
              placeholder="Enter Score Differential"
            />
          </FormGroup>
          <Button type="submit">Add Handicap Data</Button>
        </Form>
      </Section>

      <Section>
        <SectionTitle>Previous Scores</SectionTitle>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Course</th>
                <th>Event</th>
                <th>Total Score</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {scores.map((score) => (
                <tr key={score.id}>
                  <td>{score.date}</td>
                  <td>{score.courseName}</td>
                  <td>{score.eventName}</td>
                  <td>{score.totalScore}</td>
                  <td>
                    <EditButton onClick={() => handleEdit(score)}>
                      Edit
                    </EditButton>
                    <DeleteButton onClick={() => handleDelete(score.id)}>
                      Delete
                    </DeleteButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Section>

      <TrainingCalendar />
    </DashboardContainer>
  );
};

export default AdminDashboard;
