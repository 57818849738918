import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup, signInWithRedirect, getRedirectResult, onAuthStateChanged } from "firebase/auth";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
`;

const LoginCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
`;

const Title = styled.h2`
  color: #ffffff;
  margin: 0;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin: 0;
  font-size: 1rem;
`;

const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  background: #ffffff;
  color: #333333;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const ErrorMessage = styled.div`
  color: #ff4d4d;
  background: rgba(255, 77, 77, 0.1);
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
`;

const LoadingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s linear infinite;
  margin-right: 10px;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Login = ({ setIsAuthenticated }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result?.user) {
          if (result.user.email === 'contact@cameronlowegolf.com') {
            setIsAuthenticated(true);
            navigate("/admin");
          } else {
            await auth.signOut();
            setError('Unauthorized access. Please use an admin account.');
          }
        }
      } catch (error) {
        console.error("Error checking redirect result:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    checkRedirectResult();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.email === 'contact@cameronlowegolf.com') {
          setIsAuthenticated(true);
          navigate("/admin");
        } else {
          auth.signOut();
          setError('Unauthorized access. Please use an admin account.');
        }
      }
    });

    return () => unsubscribe();
  }, [setIsAuthenticated, navigate]);

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    setError("");

    try {
      // Try popup first
      try {
        const result = await signInWithPopup(auth, googleProvider);
        if (result.user.email !== 'contact@cameronlowegolf.com') {
          await auth.signOut();
          throw new Error('Unauthorized access. Please use an admin account.');
        }
      } catch (popupError) {
        // If popup fails, fall back to redirect
        if (popupError.code === 'auth/popup-blocked' ||
          popupError.code === 'auth/popup-closed-by-user' ||
          popupError.message.includes('Cross-Origin-Opener-Policy')) {
          await signInWithRedirect(auth, googleProvider);
        } else {
          throw popupError;
        }
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError(error.message);
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  };

  return (
    <LoginContainer>
      <LoginCard>
        <Title>Admin Login</Title>
        <Subtitle>Sign in with your admin account to access the dashboard</Subtitle>

        <GoogleButton
          onClick={handleGoogleLogin}
          disabled={isLoading}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <img
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="Google logo"
            />
          )}
          {isLoading ? 'Signing in...' : 'Sign in with Google'}
        </GoogleButton>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </LoginCard>
    </LoginContainer>
  );
};

export default Login;
